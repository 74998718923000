<style lang="scss">
  .v-data-table-header{
    th{
      text-transform: uppercase;
      color: #333!important;
      background-color: transparent!important;
      border: none;
    }
  }
  #app{
    .v-main__wrap{
      // overflow: hidden;
    }
    .v-application .v-data-table {
        box-shadow: none!important;
      td{
        padding: 0.75rem 1rem;
      }
    }
    .v-stepper--vertical{
      overflow: visible;
    }
  }
</style>
<template>
  <div>
      <div>
        <v-card>
          <v-toolbar
          color="grey lighten-4" class="c-view-title"
          >
            <!--<toogleNavMenu ref="toogleNavMenu" />-->
            <div class="c-view-title-text">
              <v-toolbar-title class="c-view-title-text__h1">
                Tus análisis de laboratorio
              </v-toolbar-title>
              <v-subheader class="c-view-title-text__p">
                Edita o crea nuevos ensayos de laboratorio
              </v-subheader>
            </div>
            <v-spacer></v-spacer>
            <v-chip
              class="ma-2 c-btn c-btn--primary"
              color="indigo darken-3"
              outlined
              @click="showForm()"
            >
              <!--<v-icon left>mdi-fire</v-icon>-->
              Nuevo ensayo +
            </v-chip>
          </v-toolbar>
          <v-card-title>
            <!--<v-icon>mdi-flask-outline</v-icon>-->
            <svg class="icon-svg" width="34px" height="34px" viewBox="0 0 34 34"
            style="--fillColor: #333; --bgColor: white;">
            <use xlink:href="img/iconsSVG.svg#iconEnsayoHome"></use>
            </svg>
            <span class="ensayo__text">Tus ensayos</span> -
            <span class="ensayo__num">{{ dataDT.length }}</span>

            <v-spacer></v-spacer>
            <v-col cols="3" xs="6">
              <v-text-field
                class="c-input c-input--search"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-card-text>
            <!-- <div> -->
              <v-data-table
                :search="search"
                :loading="loading"
                :headers="headers"
                :items="dataDT"
                width="100%"
                class="elevation-1"
                noResultsText='No se encontró ningún registro'
                :footer-props="{
                  'items-per-page-text':'Registro por pagina',
                }"
              >
                <template v-slot:item.ensa_informe="{ item }">
                  {{ item.ensa_informe }}
                  <v-chip x-small dark label color="c-tag c-tag--active green darken-1">
                    Activo
                  </v-chip>
                </template>

                <template v-slot:item.parametro="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ getparam(item) }}
                      </p>
                    </template>
                    <span>{{item.parametro.join()}}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-chip
                  @click="editEnsayo(item)"
                  label
                  outlined
                  >
                    <div v-if="loadEnsayo">
                      <v-progress-circular
                        color="purple darken-1"
                        indeterminate
                        :size="20"
                        :width="2"
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                    Editar
                    </div>
                  </v-chip>
                  <v-icon
                    @click="confirmDeleteEnsayo(item)"
                  >mdi-close-circle-outline</v-icon>
                </template>
              </v-data-table>
            <!-- </div> -->
          </v-card-text>
        </v-card>

        <!-- CONFIRMACION DE ELIMINAR -->
        <confirm :Father="this" ref="confirm"/>
        <!--  -->
      </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Service from '../../services/apis';

export default {
  props: {
    setSee: Function,
  },
  components: {
    confirm: () => import('./confirmDeleteEnsayo.vue'),
    // toogleNavMenu: () => import('../toogleNavMenu'),
  },
  data: () => ({
    loading: true,
    navOpen: false,
    // TABLA
    search: '',
    headers: [
      { text: 'Nombre', class: 'td-name', value: 'ensa_informe' },
      { text: 'Tipo', class: 'td-type', value: 'tipo' },
      { text: 'Parámetros', class: 'td-params', value: 'parametro' },
      { text: 'Matriz', class: 'td-matrix', value: 'matriz' },
      { text: 'Creado', class: 'td-created', value: 'create_at' },
      { text: '', class: 'td-actions', value: 'actions' },
    ],
    items: [],
    //
    itemData: null,
    loadEnsayo: false,
  }),
  computed: {
    dataDT() {
      const group = _.groupBy(this.items, 'grupo_ensayo_id');
      const data = [];
      Object.keys(group).map((i) => {
        // console.log(group);
        const d = {
          matrices: [],
          parametros: [],
        };
        // OBTIENE LAS MATRICES ID
        group[i].map((D) => {
          d.parametros = D.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.nombre);
          d.matrices.push(D.matrix.matriz);
          return true;
        });
        // UNIQUE
        d.matrices = _.uniq(d.matrices);
        d.parametros = _.uniq(d.parametros);
        // console.log(group[i][0]);
        data.push({
          tipo: group[i][0].tipos_de_ensayo.descripcion_tipo_de_ensayo,
          cod_ensayo: group[i][0].cod_ensa,
          parametro: d.parametros,
          // ensa_informe: group[i][0].ensa_informe,
          ensa_informe: group[i][0].ensayos_easypcrs[0].name_ensayo,
          matriz: d.matrices,
          grupo_ensayo_id: group[i][0].grupo_ensayo_id,
          create_at: group[i][0].ensayos_easypcrs[0].create_at,
        });
        return true;
      });
      // console.log('data', data);
      return data;
    },
  },
  methods: {
    showForm() {
      this.setSee(false, null, true);
    },
    editEnsayo(item) {
      this.loadEnsayo = true;
      this.setSee(false, item, false);
    },
    confirmDeleteEnsayo(item) {
      this.itemData = item;
      this.$refs.confirm.open(item);
    },
    async deleteEnsayo(item) {
      if (this.itemData) {
        const deletedTest = await Service.apiToken('POST', 'delete-test', this.$ls.storage.token, item);
        if (deletedTest) {
          this.init();
        }
        this.itemData = null;
      } else {

        // console.warn('No hay un elemento a eliminar');
      }
    },
    async init() {
      const response = await Service.apiToken('POST', 'get-ensayos', this.$ls.storage.token, { data: 1 });
      if (!response.error) {
        this.items = response.response;
      }
      this.loading = false;
    },
    toogleNav() {
      if (this.navOpen === true) {
        this.navOpen = false;
        document.querySelector('.v-navigation-drawer').classList.add('v-navigation-drawer--open');
        document.querySelector('.v-navigation-drawer').classList.remove('v-navigation-drawer--close');
      } else {
        this.navOpen = true;
        document.querySelector('.v-navigation-drawer').classList.add('v-navigation-drawer--close');
        document.querySelector('.v-navigation-drawer').classList.remove('v-navigation-drawer--open');
      }
    },
    getparam(item) {
      let param = item.parametro;
      if (param.length > 4) {
        param = param.slice(0, 3);
        param = `${param.join()} + ${item.parametro.length - 3}`;
      } else {
        param = param.join();
      }
      return param;
    },
  },
  async created() {
    this.init();
  },
};
</script>
