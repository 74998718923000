var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card',[_c('v-toolbar',{staticClass:"c-view-title",attrs:{"color":"grey lighten-4"}},[_c('div',{staticClass:"c-view-title-text"},[_c('v-toolbar-title',{staticClass:"c-view-title-text__h1"},[_vm._v(" Tus análisis de laboratorio ")]),_c('v-subheader',{staticClass:"c-view-title-text__p"},[_vm._v(" Edita o crea nuevos ensayos de laboratorio ")])],1),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2 c-btn c-btn--primary",attrs:{"color":"indigo darken-3","outlined":""},on:{"click":function($event){return _vm.showForm()}}},[_vm._v(" Nuevo ensayo + ")])],1),_c('v-card-title',[_c('svg',{staticClass:"icon-svg",staticStyle:{"--fillColor":"#333","--bgColor":"white"},attrs:{"width":"34px","height":"34px","viewBox":"0 0 34 34"}},[_c('use',{attrs:{"xlink:href":"img/iconsSVG.svg#iconEnsayoHome"}})]),_c('span',{staticClass:"ensayo__text"},[_vm._v("Tus ensayos")]),_vm._v(" - "),_c('span',{staticClass:"ensayo__num"},[_vm._v(_vm._s(_vm.dataDT.length))]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3","xs":"6"}},[_c('v-text-field',{staticClass:"c-input c-input--search",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.dataDT,"width":"100%","noResultsText":"No se encontró ningún registro","footer-props":{
                'items-per-page-text':'Registro por pagina',
              }},scopedSlots:_vm._u([{key:"item.ensa_informe",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.ensa_informe)+" "),_c('v-chip',{attrs:{"x-small":"","dark":"","label":"","color":"c-tag c-tag--active green darken-1"}},[_vm._v(" Activo ")])]}},{key:"item.parametro",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getparam(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.parametro.join()))])])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""},on:{"click":function($event){return _vm.editEnsayo(item)}}},[(_vm.loadEnsayo)?_c('div',[_c('v-progress-circular',{attrs:{"color":"purple darken-1","indeterminate":"","size":20,"width":2}})],1):_c('div',[_vm._v(" Editar ")])]),_c('v-icon',{on:{"click":function($event){return _vm.confirmDeleteEnsayo(item)}}},[_vm._v("mdi-close-circle-outline")])]}}])})],1)],1),_c('confirm',{ref:"confirm",attrs:{"Father":this}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }